import React from 'react';
import Banner from 'sats-ui-lib/react/banner';

import PtShopLayout from 'client/components/pt-shop-layout/pt-shop-layout';
import type { PtShopPay as Props } from './pt-shop-pay.types';

const PtShopPay: React.FC<Props> = ({ banners, debug, layout }) => (
  <PtShopLayout {...layout}>
    <div className="pt-shop-pay">
      {banners.length ? (
        <div className="pt-shop-pay__banners">
          {banners.map(banner => (
            <div key={banner.text}>
              <Banner {...banner} />
            </div>
          ))}
        </div>
      ) : null}
      {debug ? (
        <pre>
          <code>{debug}</code>
        </pre>
      ) : null}
    </div>
  </PtShopLayout>
);

export default PtShopPay;

import React, { useEffect } from 'react';
import Banner from 'sats-ui-lib/react/banner';
import LinkButton from 'sats-ui-lib/react/link-button';

import PtShopLayout from 'client/components/pt-shop-layout/pt-shop-layout';
import { replaceQueryParameters } from 'shared/replace-query-parameters';
import type { PtShop as Props } from './pt-shop.types';

const PtShop: React.FC<Props> = ({
  actions,
  banners,
  debug,
  initialOptions,
  layout,
  next,
}) => {
  // NOTE: Not super sure about this way of doing it,
  // but let's talk about it later.
  useEffect(() => {
    if (initialOptions) {
      history.replaceState(
        undefined,
        document.title,
        replaceQueryParameters(window.location.href, initialOptions)
      );
    }
  }, []);

  return (
    <PtShopLayout {...layout}>
      <div className="pt-shop">
        {banners.length ? (
          <div className="pt-shop-pay__banners">
            {banners.map(banner => (
              <div key={banner.text}>
                <Banner {...banner} />
              </div>
            ))}
          </div>
        ) : null}
        {actions.length ? (
          <div className="pt-shop-pay__actions">
            {actions.map(action => (
              <div key={action.href}>
                <a href={action.href}>{action.text}</a>
              </div>
            ))}
          </div>
        ) : null}
        {next ? (
          <div>
            <LinkButton {...next} />
          </div>
        ) : null}
        {debug ? (
          <pre>
            <code>{debug}</code>
          </pre>
        ) : null}
      </div>
    </PtShopLayout>
  );
};

export default PtShop;
